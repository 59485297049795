<!-- 楼层设置 -->
<template>
  <section class="cont floorGroup">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>PMS</el-breadcrumb-item>
        <el-breadcrumb-item>住宿设置</el-breadcrumb-item>
        <el-breadcrumb-item>楼层设置</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-box">
      <el-alert type="warning" title="设置楼层后，可在日历房态页面按照楼层进行筛选。例：可设置【1楼】分组，将所有位于1楼的房间号分配在该分组下" show-icon></el-alert>
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <el-row class="right-btn">
            <el-button @click="addEditEvnet('edit')" :disabled="show || !tableData.length"><span>{{ show? "编辑中":"编辑" }}</span></el-button>
            <el-button class="bg-gradient" type="primary" style="margin-left: 16px;" @click="addEditEvnet('add')"><span>新增楼层</span></el-button>
          </el-row>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border fit :stripe="true" v-loading="loading">
          <el-table-column label="楼层名称" width="400">
            <template slot-scope="scope">
              <div v-if="!show">
                {{ scope.row.name }}
              </div>
              <div style="display: flex;align-items: center;" v-else>
                <el-input v-model="scope.row.name" clearable></el-input>
                <div style="border: 3px solid;margin-left: 16px;color: #f5222d;border-radius: 5px;" @click="deleteBt(scope)">
                  <i class="el-icon-delete" style="font-size: 32px;"></i>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="房间号">
            <template slot-scope="scope">
              <draggable v-model="scope.row.roomsList" group="site" animation="300" chosenClass="chosen" :disabled="!show">
                <transition-group style="width: 100%;display: inline-block;">
                  <div class="roomNo" :class="{item:show}" v-for="item in scope.row.roomsList" :key="item.id">{{item.roomNo}}</div>
                </transition-group>
              </draggable>
            </template>
          </el-table-column>
          <div slot="empty" style="padding: 40px 0;color: #999;line-height: 19px;">
            <div style="margin-top: 80px; margin-bottom: 80px;">
              <img src="../../../../assets/group_empty.png" alt="空背景" style="width: 145px; height: 137px;">
              <div style="color: #666;"> 您还没有楼层，点击 <span style="color: #053dc8;cursor: pointer;" @click="addEditEvnet('add')">新增楼层</span> 即可创建 </div>
            </div>
          </div>
        </el-table>
      </el-row>
    </el-row>
    <!-- 未分组房间 -->
    <div class="ungrouped-container" v-if="tableData.length">
      <el-row class="title">
        <span class="text-16-primary">未分组房间</span>
        <span class="text-12-tip">点击编辑才可拖拽房间至楼层</span>
      </el-row>
      <el-row class="body">
        <draggable v-model="roomNoList" group="site" animation="300" chosenClass="chosen" :disabled="!show">
          <transition-group style="width: 100%;display: inline-block;">
            <div class="roomNo" :class="{item:show}" v-for="item in roomNoList" :key="item.id">{{item.roomNo}}</div>
          </transition-group>
        </draggable>
      </el-row>
    </div>
    <!-- 底部按钮 取消/保存 -->
    <div class="action-container" v-if="show">
      <el-button @click="addEditEvnet('cancel')">取消</el-button>
      <el-button type="primary" style="margin-left: 16px;" @click="addEditEvnet('conserve')">保存</el-button>
    </div>
  </section>
</template>

<script>
//导入draggable组件
import draggable from 'vuedraggable';
import { mapState } from "vuex";
import { pms } from '@/api/interface/pms';
export default {
  //注册draggable组件
  components: { draggable },
  data(){
    return{
      hotelId:'',          // 酒店ID
      show: false,         // 分组编辑状态
      roomNoList:[] ,      // 未分组房间
      tableData:[],        // 表格数据
      loading: false,      // 表格加载效果
      removeRoomGroupsList:[],// 删除的分组ID

      prompt: '提示！',
      update_success: '修改成功！',
      confirm_remove: '确认要删除这个分组吗?',
    }
  },
  computed:{
    ...mapState(['hotelInfo']),
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if(oldVal && newVal.id !== oldVal.id){
        this.hotelId = this.hotelInfo.id;
        this.getRoomNo();
        this.getRoomGroupList();
      }
    }
  },
  mounted(){
    this.hotelId = this.hotelInfo.id;
    this.getRoomNo();
    this.getRoomGroupList();
  },
  methods:{
    // 获取未分组房间号
    getRoomNo(){
      const url = pms.getNotFloor;
      const param = {
        hotelId:this.hotelId,                            // 酒店ID
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.roomNoList = res.records;
        }
      })
    },
    // 获取分组列表
    getRoomGroupList(){
      const url = pms.getFloorGroup+`?limit=10&page=1`;
      const param = {
        hotelId:this.hotelId,                            // 酒店ID
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.tableData = res.records;
        }
      })
    },
    // 新增分组 / 编辑 / 取消
    addEditEvnet(state){
      this.show = true;
      if (state === "cancel"){          // 取消 按钮
        this.show = false;
        this.getRoomNo();
        this.getRoomGroupList();
        this.removeRoomGroupsList = [];
      }else if (state === "conserve") { // 保存 按钮
        if ( this.tableData.some(item => item.name === "") ) {
          this.$message({showClose: true,message:"请填写分组名称",type: 'warning'});
        }else{
          let roomGroupsList = [];
          for (let index = 0; index < this.tableData.length; index++) {
            let roomId = [];
            for (let i = 0; i < this.tableData[index].roomsList.length; i++) {
              roomId.push({"roomId":this.tableData[index].roomsList[i].id});
            }
            roomGroupsList.push( {"name":this.tableData[index].name,"id": this.tableData[index].id ,"rooms": roomId.length===0?JSON.stringify([{"roomId": ""}]):JSON.stringify(roomId) }  );
          }
          const url = pms.conserveFloorGroup;
          const param = {
            floorList:roomGroupsList,
            hotelId:this.hotelId,
            removeFloorList:this.removeRoomGroupsList
          }
          this.$axios.post(url, param, 'json').then((res) => {
            if (res.success) {
              this.show = false;
              this.$message({showClose: true,message: this.update_success,type: 'success'});
              this.getRoomNo();
              this.getRoomGroupList();
              this.removeRoomGroupsList = [];
            }
          })
        }
      }else if (state === "add") {      // 新增 按钮
        this.tableData.unshift({ name:"" , roomsList:[] });
      }
    },
    // 删除 按钮
    deleteBt(scope){
      this.$confirm(this.confirm_remove, this.prompt, {
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        type: "warning",
      }).then(() => {
        this.removeRoomGroupsList.push(scope.row.id);
        for (let index = 0; index < this.tableData[scope.$index].roomsList.length; index++) {
          this.roomNoList.push( this.tableData[scope.$index].roomsList[index] );
        }
        this.tableData.splice(scope.$index,1);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.floorGroup{
  .el-alert--warning.is-light{
    padding: 10px;
    border: 1px solid #fa0;
    margin-bottom: 20px;
  }
  .ungrouped-container{
    .title{
      margin-top: 10px;
      background: #FFFFFF;
      padding:16px 50px;
      border-bottom: 1px solid #E9EAED;
      .text-16-primary{
        color: #333;
        font-size: 17px;
      }
      .text-12-tip{
        color: #999;
        margin-left: 8px;
        font-size: 13px;
      }
    }
    .body{
      background: #FFFFFF;
      padding:16px 50px;
      border-bottom: 1px solid #E9EAED;
    }
  }
  .roomNo{
    display: inline-block;
    background: rgb(246, 247, 250);
    border-radius: 2px;
    border: 1px solid rgb(240, 241, 245);
    padding:4px 16px;
    margin:0 8px 8px 0;
  }
  .action-container{
    width: 100%;
    background: rgb(255, 255, 255);
    box-shadow: rgba(5, 61, 200, 0.1) 0px 4px 16px 0px;
    text-align: end;
    padding:12px 24px;
    position: fixed;
    bottom: 0;
    right: 0;
  }
  /*被拖拽对象的样式*/
  .item {
    padding: 6px;
    background-color: #fdfdfd;
    border: solid 1px #eee;
    margin-bottom: 10px;
    cursor: move;
  }
  /*选中样式*/
  .chosen {
    border: solid 2px #3089dc !important;
  }
}
</style>
